@import 'mixins';

.hide { display: none !important; }

ul.element-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.icon-container {
  margin-right: 0.5rem;
  color: #1890D7;
}

.error {
  color: #cc0000;
  border-color: #cc0000;
}

/* Textarea */
textarea {
  min-height: 12.5rem;
  padding-top: 2rem;
}

/* Form */
form {
  .form-group {
    display: flex;

    &--column {
      flex-direction: column;
      > * {
        margin-right: 0 !important;
        margin-bottom: 1rem;
      }
    }

    &--row {
      flex-direction: row;

      @include phones {
        flex-direction: column;
      }
    }

    > * {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form-control {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    input:not([type=checkbox]), textarea {
      height: 4.375rem;
      width: 100%;
      font-size: 1.25rem;
      color: #A5A5A5;
      border: solid 0.0625rem #A5A5A5;
      border-radius: 0.2rem;
      padding: 0 0 0 2rem;
    }

    &__placeholder {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      bottom: 0;
      left: 2rem;
      align-items: center;
      color: #C7C7CD;
      font-size: 1.25rem;

      .asterisk {
        pointer-events: none;
        color: #D65641;
        padding-left: 0.2rem;
      }
    }

    &--textarea {
      .form-control__placeholder {
        top: 2rem;
        bottom: unset;
      }
    }
  }

  @include phones {
    > .form-control {
      flex-direction: column;
    }
    .form-control + .form-control {
      margin-top: 1rem;
    }

    button {
      margin: 1rem 0 2rem 0;
    }
  }
}

/* Select */
.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #58585B;
  border-radius: 0.2rem;
  background-color: #fff;

  .custom-select {
    position: relative;
    display: flex;
    flex-direction: column;

    &__trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.5rem 0 1.75rem;
      font-size: 1.111rem;
      font-weight: 300;
      color: #58585B;
      height: 70px;
      line-height: 70px;
      cursor: pointer;

      &:focus-visible {
        outline: none;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px #005fcc, 0 0 0 4px white;
      }

      &:invalid:focus {
        border-color: rgb(214, 86, 65);
        box-shadow: 0 0 0 2px rgb(214, 86, 65), 0 0 0 4px white;
      }
    }

    &.open {
      .custom-options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
  }
  .custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #58585B;
    border-top: 0;
    background: #fff;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
    margin: 0;
    padding: 0;

    .custom-option {
      position: relative;
      display: block;
      padding: 0 1.5rem 0 2rem;
      font-size: 1.111rem;
      font-weight: 300;
      color: #58585B;
      line-height: 70px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #b2b2b2;
      }

      &.selected {
        color: #ffffff;
        background-color: #A5A5A5;
      }
    }
  }

  .arrow {
    position: relative;
    height: 15px;
    width: 15px;
  }
  .arrow::before, .arrow::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.5s;
  }
  .arrow::before {
    left: -5px;
    transform: rotate(-45deg);
    background-color: #58585B;
  }
  .arrow::after {
    left: 5px;
    transform: rotate(45deg);
    background-color: #58585B;
  }
  .open .arrow::before {
    left: -5px;
    transform: rotate(45deg);
  }
  .open .arrow::after {
    left: 5px;
    transform: rotate(-45deg);
  }
}

.styled-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  transform: translateY(-.225rem); // magic values to align checkbox with text
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    padding-left: 2rem;
    color: #58585B;
    line-height: 1.35rem;
  }

  & + label:before {
    content: '';
    position: absolute;
    top: 0;
    transform: translateY(-.225rem); // magic values to align checkbox with text
    display: block;
    width: 20px;
    height: 20px;
    border: solid 0.0625rem #A5A5A5;
    border-radius: 4px;
    background: white;
  }

  &:hover + label:before {
    background: #b8b8b8;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: #1890D7;
  }

  &:disabled + label {
    color: #ddd;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: translateX(.375rem) translateY(0.375rem) rotate(45deg); // magic values to align checkmark with box
  }

  &:invalid:focus + label:before {
    border-color: rgb(214, 86, 65);
    box-shadow: 0 0 0 3px rgba(214, 86, 65, 0.25);
  }
}

.styled-checkbox + .styled-checkbox {
  margin-top: .75rem;
}

.text-blue {
  color: #01375C;
}

.text-venice-blue {
  color: #0A4F7D;
}

.text-size-16 {
  font-size: 1rem;
}

.text-size-20 {
  font-size: 1.25rem;
}

.text-size-32 {
 font-size: 2rem;
}
