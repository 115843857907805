.card {
  margin-top: 1rem;
  color: #01375C;

  &:after {
    display: block;
    content: '';
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: solid 0.0625rem #A5A5A5;
    grid-column: 1/3;
  }
  &--expandable {
    display: grid;
    grid-template-columns: minmax(auto, 1fr) 2fr;
    column-gap: 1rem;

    &__showMore {
      grid-column: 1/3;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__image {
    img {
      width: 100%;
    }
    .caption {
      font-size: 0.75rem;
      font-color: #58585B;
    }
  }

  &__content {
    grid-column: 1/3;
    display: inline-flex;
    flex-direction: column;
    align-self: center;

    .sub-heading {
      color: #1890D7;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }

  &__image + &__content {
    grid-column: 2;
  }

  &:last-of-type {
    &:after {
      border: none;
      padding-bottom: 0;
    }
  }
}
